<template>
  <div class="table" ref="tableRef">
    <artmisads-table
      :columns="columns"
      row-key="id"
      :data-source="props.dataList"
      :pagination="{
        total: props.listTotal,
        current: props.params.page,
        pageSize: props.params.size,
        showSizeChanger: true
      }"
      :change="onChangePage"
      tableLayout="fixed"
    >
      <template #bodyCell="{ column, record, text }">
        <template v-if="column.name === 'showCurrency'">
          {{AMAZON_CURRENCY_MAPPER[record.marketplace]}}{{text}}
        </template>
        <template v-if="column.name === 'marketplace'">
          <div class="table-marketplace">
           <div>
              <Icon :name="COUNTRY_BASE_MAP[text].icon"></Icon>
              <span>{{text}}</span>
           </div>
          </div>
        </template>
        <template v-if="column.name === 'status'">
          <financeStatus :type="text"/>
        </template>
        <template v-if="column.name === 'transactionCycleStartDate'">
          {{record.transactionCycleStartDate}} - {{record.transactionCycleEndDate}}
        </template>
        <template v-if="column.name === 'billingDateTimestamp'">
          {{moment(record.billingDateTimestamp).tz(AMAZON_COUNTRY_TIME_TZ[record.marketplace]).format('YYYY-MM')}}
        </template>
        <template v-if="column.name === 'actions'">
          <div class="actions">
            <artmisads-select-button @click="() => props.setDetailData(record, 'detail')" size="small">Details</artmisads-select-button>
            <artmisads-select-button v-if="!record.bandAccountName && (record.status === 'not_paid' || record.status === 'delayed')" @click="() => props.setDetailData(record, 'invoice')"  size="small">Invoice</artmisads-select-button>
            <!-- <artmisads-select-button @click="() => props.setDetailData(record, 'invoice')"  size="small">Invoice</artmisads-select-button> -->
          </div>
        </template>
      </template>
    </artmisads-table>
  </div>
</template>
<script lang="jsx" setup>
  import moment from 'moment';
  import { ref } from 'vue';
  import { keyBy } from 'lodash';
  import Icon from '~/components/Icon.vue';
  import financeStatus from '~/components/financeStatus';
  import { COUNTRY_BASE, AMAZON_COUNTRY_TIME_TZ, AMAZON_CURRENCY_MAPPER } from "~/contants/common";

  const COUNTRY_BASE_MAP = keyBy(COUNTRY_BASE, 'host');

  const tableRef = ref(null);
  const props = defineProps([ 'listTotal', 'setParams', 'params', 'dataList', 'getList', 'setSpinning', 'setDetailData']);
  const columns = [
    {
      title: 'Billing Date',
      dataIndex: 'billingDateTimestamp',
      width: 100,
      name: 'billingDateTimestamp',
    },
    {
      title: 'Payment ID',
      dataIndex: 'billingId',
      width: 140,
    },
    {
      title: 'Transaction Cycle',
      dataIndex: 'transactionCycleStartDate',
      width: 130,
      name: 'transactionCycleStartDate'
    },
    {
      title: 'Marketplace',
      dataIndex: 'marketplace',
      width: 120,
      name: 'marketplace'
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      width: 100,
      name: 'showCurrency',
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      width: 100,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      name: 'status',
      width: 100,
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      name: 'actions',
      width: 130,
    },
  ]

  const onChangePage = (data) => {
    const { current, pageSize } = data;
    let newParams = { ...props.params };
    newParams.page = current;
    newParams.size = pageSize;
    // props.setParams(newParams);
    props.getList();
  }

</script>
<style lang="less" scoped>
  .table {
    padding: 16px 0;
    :deep(.ant-tooltip-arrow) {
      display: none;
    }
    :deep(.ant-table) {
      .table-marketplace {
        display: inline-block;
        > div {
          display: flex;
          background-color: var(--bg-gray-8);
          border-radius: 4px;
          padding: 0 6px;
          line-height: 20px;
          > svg {
            width: 20px;
            height: 20px;
            margin-right: 4px;
            align-self: center;
          }
        }
        
      }
      .ant-table-thead {
        .ant-table-cell {
          line-height: 24px;
        }
      }
      .actions {
        display: flex;
        button {
          align-self: center;
          margin-right: 8px;
        }
      }
    }
  }
</style>