<template>
  <div class="method-wrap">
    <p>Choose Payment Method</p>
    <div class="cont">
      <span
        class="bank"
        :class="props.methodType === 'bank' ? 'select' : ''"
        @click="() => onClick('bank')"
      >
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-yinhangka1"></use>
        </svg>
        <span>Bank Transfer</span>
      </span>
      <span
        class="paypal"
        @click="() => onClick('paypal')"
        :class="props.methodType === 'paypal' ? 'select' : ''"
      >
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#icon-paypal1"></use>
        </svg>
        <span>Pay via Paypal</span>
      </span>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";

const props = defineProps(["methodType", "setMethodType"]);

const onClick = (status) => {
  props.setMethodType(status);
};

</script>
<style lang="less" scoped>
.method-wrap {
  > p {
    font-weight: 600;
    font-size: 20px;
    color: var(--dark-1);
    letter-spacing: 0.5px;
    line-height: 28px;
    margin-bottom: 16px;
  }
  .cont {
    display: flex;
    .bank {
      cursor: pointer;
      padding: 16px 17px;
      border: 1px solid var(--border-1);
      box-shadow: 0 1px 0 0 var(--box-shadow-2);
      border-radius: 8px;
      display: flex;
      margin-right: 16px;
      &.select {
        border: 1px solid var(--primary-border-2);
        box-shadow: var(--section-box-shadow-4);
      }
      svg {
        width: 24px;
        height: 24px;
        align-self: center;
      }
      > span {
        align-self: center;
        color: var(--dark-1);
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 22px;
        margin-left: 8px;
      }
    }
    .paypal {
      cursor: pointer;
      padding: 16px 15px;
      border: 1px solid var(--border-1);
      box-shadow: 0 1px 0 0 var(--box-shadow-2);
      border-radius: 8px;
      display: flex;
      &.select {
        border: 1px solid var(--primary-border-2);
        box-shadow: var(--section-box-shadow-4);
      }
      > span {
        align-self: center;
        color: var(--dark-1);
        font-weight: 500;
        font-size: 16px;
        letter-spacing: 0.5px;
        line-height: 22px;
        margin-left: 8px;
      }
      svg {
        width: 24px;
        height: 24px;
      }
    }
  }
}
</style>
