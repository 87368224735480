<template>
  <div class="finance-status-wrap">
    <div :class="props.type" class="finance-status">
      <Icon :name="FINANCE_STATUS_ICON_MAP[props.type]"></Icon>
      <span>{{ FINANCE_SELLER_STATUS_MAP[props.type].label }}</span>
    </div>
  </div>
</template>
<script setup>
  import Icon from './Icon';
  import { FINANCE_SELLER_STATUS_MAP, FINANCE_STATUS_ICON_MAP } from '~/contants/finance';
  const props = defineProps(["type"]);
</script>
<style lang="less" scoped>
.finance-status-wrap {
  display: inline-block;
}
.finance-status {
  display: flex;
  background-color: var(--bg-gray-8);
  border-radius: 4px;
  padding: 0 6px;
  line-height: 20px;
  &.paid {
    background-color: var(--green-7);
    svg {
      fill: var(--green-3);
    }
  }
  &.delayed {
    background-color: var(--red-bg);
    color: var(--color-white);
  }
  &.paid_review{
    background-color: var(--bg-gray-8);
    svg {
      fill: var(--yellow-4);
    }
  }
  &.part_paid,
  &.not_paid {
    background-color: var(--yellow-3);
    svg {
      fill: var(--yellow-2);
    }
  }
  > svg {
    width: 14px;
    height: 14px;
    margin-right: 6px;
    align-self: center;
  }
  > span {
    font-size: 12px;
  }
}
</style>
