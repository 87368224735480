<template>
  <artmisads-modal
    :open="true"
    title="Invoice"
    :width="720"
    :onCancel="() => props.setDetailData({})"
    :destroyOnClose="true"
  >
    <a-spin :spinning="spinning">
      <div class="invoice">
        <div class="head">
          <span class="name">Amount to Pay:</span>
          <div class="text">
            <span class="num"> {{AMAZON_CURRENCY_MAPPER[props.detailData.marketplace]}}{{props.detailData.amount}}</span>
            <artmisads-select-button @click="toInvoicePdf">
              <svg class="icon icon-daochu1" aria-hidden="true">
                <use xlink:href="#icon-daochu1"></use>
              </svg>
              Download Invoice
            </artmisads-select-button>
          </div>
        </div>
        <div class="method">
          <method
            :methodType="methodType"
            :setMethodType="setMethodType"
          />
        </div>
        <div class="main-cont" v-if="methodType === 'bank'">
          <p class="notice">Please fill in the relevant information after completing the bank transfer.</p>
          <div class="input-wrap">
            <a-form>
              <a-form-item
                name="bandAccountName"
                v-bind="validateInfos.bandAccountName"
              >
                <div class="input-item">
                  <span class="text">Bank Account Name<span>*</span></span>
                  <artmisads-input style="width: 324px;" @blur="() => validate('bandAccountName')" size="middle" v-model:value="modelRef.bandAccountName"/>
                </div>
              </a-form-item>
            </a-form>
            <a-form>
              <a-form-item
                name="bandTransferNo"
                v-bind="validateInfos.bandTransferNo"
              >
                <div class="input-item">
                  <span class="text">Bank Transfer NO.<span>*</span></span>
                  <artmisads-input style="width: 324px;" @blur="() => validate('bandTransferNo')" size="middle" v-model:value="modelRef.bandTransferNo"/>
                </div>
              </a-form-item>
            </a-form>
          </div>
          <p class="img-notice">Upload Transfer receipt photo</p>
          <div class="upload-img">
            <a-form
              :label-col="{ style: { width: '60px' } }"
              :wrapper-col="{ span: 20 }"
              layout="horizontal"
              style="color: aquamarine"
            >
              <a-form-item>
                <UploadImage
                  :configs="{
                    maxCount: 1,
                    maxSize: 2,
                    spin: true,
                  }"
                  @upload-file="uploadImg"
                  #content
                >
                  <div class="upload_part">
                    <div v-if="props.detailData.bankTransferReceiptImageUrl || bankTransferReceiptImageUrl" class="upload-img">
                      <img :src="imgCache(props.detailData.bankTransferReceiptImageUrl || bankTransferReceiptImageUrl)" alt="" />
                    </div>
                    <div v-else class="upload-icon">
                      <svg class="icon" aria-hidden="true">
                        <use xlink:href="#icon-shangchuan-xi"></use>
                      </svg>
                    </div>
                  </div>
                </UploadImage>
              </a-form-item>
            </a-form>
          </div>
          <p class="note">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-info"></use>
            </svg>
            For bank transfer, after you submit the transfer information, the payment status will be updated after the ArtemisAds operation team verifies the transfer result.
          </p>
        </div>
        <div class="main-cont" v-else-if="methodType === 'paypal'">
          <p class="paypal-text">ArtemisAds has partnered with PayPal to ensure the security of your transactions. Click “Go To Paypal” to be redirected to PayPal to complete the payment.</p>
          <p class="note note-paypal">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-info"></use>
            </svg>
            Please note that when you choose to pay via PayPal, PayPal will charge a 3.5% handling fee, which is not related to ArtemisAds.
          </p>
        </div>
      </div>
    </a-spin>
    <template #footer>
      <div class="invoice-btn">
        <artmisads-button @click="() => props.setDetailData({})">Cancel</artmisads-button>
        <artmisads-button type="primary" @click="financeSellerBillingsSubmit" v-show="methodType === 'bank'">Submit</artmisads-button>
        <artmisads-button type="primary" @click="goToPaypal" v-show="methodType === 'paypal'">Go To Paypal</artmisads-button>
        <!-- <span id="paypal-button-container" v-show="methodType === 'paypal'"></span> -->
      </div>
    </template>
  </artmisads-modal>
</template>

<script setup>
import { useRouter } from "vue-router";
import { Form } from 'ant-design-vue';
import { ref, reactive, onMounted } from "vue";
import { AMAZON_CURRENCY_MAPPER } from '~/contants/common';
import { financeApi } from "~/server";
import UploadImage from '@/components/uploadImage.vue'
import method from './method.vue'
import { imgCache } from '~/utils'
import { loadScript } from "@paypal/paypal-js";

const router = useRouter();
const props = defineProps(["detailData", 'setDetailData', 'hasCompleteInfo', 'setShowNotice']);
const spinning = ref(false);
const methodType = ref('bank')

const bankTransferReceiptImageUrl = ref('');

const useForm = Form.useForm;
  const modelRef = reactive({
    bandAccountName: props.detailData.bandAccountName,
    bandTransferNo: props.detailData.bankTransferNo,
  });

  const rulesRef = reactive({
    bandAccountName: [{
      required: true,
      message: 'Please fill in required information.',
      trigger: ['blur']
    }],
    bandTransferNo: [{
      required: true,
      message: 'Please fill in required information.',
      trigger: ['blur']
    }]
  });

  const { validate, validateInfos } = useForm(modelRef, rulesRef);

  const uploadImg = (res) => {
  try {
    if (res.data.url) {
      bankTransferReceiptImageUrl.value = res.data.url;
    }
  } catch (error) {
    console.log('upload-false')
  }
}

const goToPaypal = async () => {
  spinning.value = true;
  let paramsData = { billingId: props.detailData.billingId};
  const res = await financeApi.financeSellerPaymentPaypalCheckoutOrders(paramsData);
  const { success, data } = res;
  if (success) {
    if (data.paypalPaymentUrl) {
      localStorage.setItem('SELLE_PAYPAL_ORDER_ID', data.paypalOrderId)
      window.open(data.paypalPaymentUrl, '_blank');
    }
  }
  spinning.value = false;
}

const paypalInitialize = async () => {
  let paypal;
  try {
    paypal = await loadScript({
      clientId:
        "AZbgmxkdM28bAszLHhl2QRgnC3zgEveU30XlP_Tv18qnp22asO6dwbvSCTisl9qCuUpKpaKJTkV7COyN",
    });
  } catch (error) {
    console.error("failed to load the PayPal JS SDK script", error);
  }

  if (paypal) {
    try {
      await paypal
        .Buttons({
          style: {
            layout: 'horizontal',
            shape: "pill",
            borderRadius: 20,
            color: 'gold',
            height: 40
          },
        })
        .render("#paypal-button-container");
    } catch (error) {
      console.error("failed to render the PayPal Buttons", error);
    }
  }
};

onMounted(() => {
  // paypalInitialize();
});

const setMethodType = status => {
  methodType.value = status;
}

const financeSellerBillingsSubmit = async () => {
  const validateRes = await validate();
  if (!validateRes.errorFields) {
    spinning.value = true;
    let paramsData = {...validateRes, billingId: props.detailData.billingId};
      paramsData.bankTransferReceiptImageUrl = bankTransferReceiptImageUrl.value ||  props.detailData.bankTransferReceiptImageUrl || '';
    const res = await financeApi.financeSellerBillingsSubmit(paramsData);
    const { success } = res;
    if (success) {
      props.setDetailData({}, '', true);
    }
    spinning.value = false;
  }
};

const toInvoicePdf = () => {
  if (props.hasCompleteInfo) {
    const { href } = router.resolve({ path: `/seller/invoice-pdf/${props.detailData.billingId}` });
    window.open(href, '_blank');
  } else {
    props.setShowNotice(true);
  }
}


</script>

<style lang="less" scoped>
.invoice-btn {
  display: flex;
  #paypal-button-container {
    margin-left: 8px;
    height: 40px;
  }
}
.invoice {
 
  .main-cont {
    background-color: var(--bg-gray-1);
    padding: 16px;
    border-radius: 8px;
    margin-top: 12px;
  }
  .paypal-text {
    font-weight: 600;
    font-size: 16px;
    color: var(--dark-1);
    letter-spacing: 0.4px;
    line-height: 24px;
  }
  .method {
    margin-top: 40px;
  }
  .head {
    background: var(--bg-gray-8);
    border-radius: 8px;
    padding: 12px 8px 10px 12px;
    .name {
      font-weight: 500;
      font-size: 13px;
      color: var(--dark-2);
      letter-spacing: 0.3px;
      line-height: 20px;
    }
    .text {
      display: flex;
      justify-content: space-between;
      .num {
        font-weight: 600;
        font-size: 18px;
        color: var(--dark-1);
        letter-spacing: 0.4px;
        line-height: 32px;
      }
      button {
        display: flex;
        svg {
          width: 20px;
          height: 20px;
          margin-right: 8px;
          align-self: center;
        }
      }
    }
  }
  .notice {
    font-weight: 600;
    font-size: 16px;
    color: var(--dark-1);
    letter-spacing: 0.4px;
    line-height: 24px;
  }
  .input-wrap {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
    :deep(.ant-form-item) {
      margin-bottom: 0;
    }
    .input-item {
      display: flex;
      flex-wrap: wrap;
      width: 308px;
      .text {
        color: var(--dark-2);
        font-weight: 500;
        line-height: 18px;
        font-size: 13px;
        margin-bottom: 4px;
        display: flex;
        > span {
          color: var(--red-text);
          margin-left: 3px;
          font-size: 14px;
          font-weight: 600;
          line-height: 18px;
        }
      }
    }
  }
  .img-notice {
    color: var(--dark-2);
    font-weight: 500;
    font-size: 13px;
    letter-spacing: 0.3px;
    line-height: 18px;
    margin-top: 24px;
  }
  .note {
    line-height: 18px;
    color: var(--dark-3);
    font-weight: 400;
    font-size: 12px;
    letter-spacing: 0.3px;
    margin-top: 40px;
    svg {
      width: 14px;
      height: 14px;
      fill: var(--dark-3);
      margin-right: 1px;
      vertical-align: -3px;
    }
  }
  .note-paypal {
    margin-top: 12px;
  }
  .upload-img {
    margin-top: 4px;
    > img {
      width: 120px;
      height: 120px;
      object-fit: contain;
    }
    :deep(.ant-upload) {
      background-color: var(--bg-gray-6);
      width: 120px;
      height: 120px;
      border-radius: 8px;
      cursor: pointer;
      .upload_part {
        width: 120px;
        height: 120px;
      }
      .upload-icon {
        display: flex;
        justify-content: center;
        height: 100%;
        svg {
          align-self: center;
          width: 32px;
          height: 32px;
          fill: var(--dark-3);
        }
      }
    }
  }
}
</style>
