import client from '../client';

export default {
  getAmazonConnections: data => {//获取列表
    return client.get('seller/connections',{params:data}).then(res=>res.data);
  },
  bindAmazonConnections:data => {//绑定
    return client.post(`seller/connections/bind`, data).then(res=>res.data);
  },
  unbindAmazonConnections:data => {//绑定
    return client.delete(`seller/connections/${data.connectionId}`).then(res=>res.data);
  },
  activateAmazonConnections:data => {//activate
    return client.post(`seller/connections/${data.connectionId}/activate`, data).then(res=>res.data);
  },
  deactivateAmazonConnections:data => {//deactivate
    return client.post(`seller/connections/${data.connectionId}/deactivate`, data).then(res=>res.data);
  },
  getSellerAccountInfoGetAccountInfo: data => {//获取列表
    return client.get('seller/account/info/getAccountInfo',{params:data}).then(res=>res.data);
  },
  getSellerBillingAddressGetBillingAddressInfo: data => {//获取列表
    return client.get('seller/billing/address/getBillingAddressInfo',{params:data}).then(res=>res.data);
  },
  sellerBillingAddressSave:data => {//deactivate
    return client.post(`seller/billing/address/save`, data).then(res=>res.data);
  },
  getSellerAccountInfoGetCountry: data => {//获取列表
    return client.get('seller/account/info/getCountry',{params:data}).then(res=>res.data);
  },
  sellerAccountInfoSave:data => {//deactivate
    return client.post(`seller/account/info/save`, data).then(res=>res.data);
  },

  getSellerSettingsCommissionRuleList: data => {//获取列表
    return client.get('seller/settings/commissionRule/list',{params:data}).then(res=>res.data);
  },
  sellerSettingsCommissionRuleCreate:data => {//deactivate
    return client.post(`seller/settings/commissionRule/create`, data).then(res=>res.data);
  },
  sellerSettingsCommissionRuleDelete:data => {//deactivate
    return client.patch(`seller/settings/commissionRule/delete/${data.id}`, data).then(res=>res.data);
  },
  getSellerSettingsCommissionDetail: data => {//获取列表
    return client.get(`seller/settings/commissionRule/detail/${data.id}`,{params:data}).then(res=>res.data);
  },
  sellerSettingsCommissionRuleExpire:data => {//deactivate
    return client.patch(`seller/settings/commissionRule/expire/${data.id}`, data).then(res=>res.data);
  },
  getSellerSettingsCommissionRuleSearchPublisher: data => {//获取列表
    return client.get('seller/settings/commissionRule/search/publisher',{params:data}).then(res=>res.data);
  },
  sellerSettingsCommissionRuleUpdate:data => {//deactivate
    return client.put(`seller/settings/commissionRule/update`, data).then(res=>res.data);
  },
}