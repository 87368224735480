
import { keyBy } from 'lodash';

export const FINANCE_STATUS_ICON_MAP = {
  pending: 'jindu-cu',
  not_paid: 'a-warring-tianchong3',
  part_paid: 'a-warring-tianchong3',
  paid_review: 'jindu-cu',
  paid: 'success-tianchong',
  delayed: 'a-warring-tianchong3',
}

export const FINANCE_SELLER_STATUS = [
  { label: 'Part Paid', value: 'part_paid' },
  { label: 'Reviewing', value: 'paid_review' },
  { label: 'Not Paid', value: 'not_paid' },
  { label: 'Paid', value: 'paid' },
  { label: 'Delayed', value: 'delayed' },
]

export const FINANCE_SELLER_STATUS_MAP = keyBy(FINANCE_SELLER_STATUS, 'value')