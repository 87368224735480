<template>
  <div class="payments">
    <a-spin :spinning="spinning">
      <breadcrumbs :breadcrumbsData="breadcrumbsData" #icon>
        <svg class="icon" aria-hidden="true" style="fill: #fff">
          <use xlink:href="#icon-yinhangka"></use>
        </svg>
      </breadcrumbs>
      <div class="content">
        <filterCont
          :params="params"
          :getData="getData"
          :setParams="setParams"
        />
        <tableContent
          :params="params"
          :dataList="list"
          :getList="getData"
          :listTotal="listTotal"
          :setDetailData="setDetailData"
        />
      </div>
    </a-spin>
    <invoiceModal
      :setDetailData="setDetailData"
      :detailData="detailData"
      :hasCompleteInfo="hasCompleteInfo"
      :setShowNotice="setShowNotice"
      v-if="detailData.type === 'invoice'"
    />
    <detailModal
      :setDetailData="setDetailData"
      :detailData="detailData"
      :hasCompleteInfo="hasCompleteInfo"
      :setShowNotice="setShowNotice"
      v-if="detailData.type === 'detail'"
    />
    <artmisads-modal
      v-model:open="showNotice"
      title="Notice"
      :closable="false"
      :width="600"
      :destroyOnClose="true"
      :footer="null"
      :onCancel="() => setShowNotice(false)"
    >
      <div class="notice-content">
        <p>Sorry, please complete your enterprise information and billing information first.</p>
        <artmisads-button @click="toAccountInfo" type="primary">Complete Now</artmisads-button>
      </div>
    </artmisads-modal>
  </div>
</template>
<script setup>
  import { useRouter } from "vue-router";
  import { ref, onMounted, watch } from "vue";
  import { financeApi } from "~/server";
  import { connectionApi } from "@/server/seller/index";
  import filterCont from './filterCont';
  import breadcrumbs from "../../../breadcrumbs.vue";
  import tableContent from './tableContent';
  import detailModal from './detailModal';
  import invoiceModal from './invoiceModal';
  import { useUserInfoStore } from "@/storeData";
  import { CURRENCY_MAPPER } from "@/contants/common";
  import moment from 'moment';

  const breadcrumbsData = {
    title: "Payments",
    color:'rgba(250, 172, 38, 1)',
    links: [
      {
        name: "Home",
        path: "/seller/dashboard",
      },
      {
        name: "Finance",
        path: "/seller/finance/payments",
      },
      {
        name: "Payments",
        path: "/seller/finance/payments",
      },
    ],
  };
  const router = useRouter();
  const showNotice = ref(false)
  const hasCompleteInfo = ref(true);
  const user = useUserInfoStore();
  const detailData = ref({});
  const spinning = ref(false);
  const list = ref([]);
  const listTotal = ref(0);
  const countryCode = user?.userInfo?.countryCode || 'US';
  const params = ref({
    marketplace: CURRENCY_MAPPER[countryCode].host,
    startBillingDateStr: moment().subtract(31, "day").valueOf(),
    endBillingDateStr: moment().valueOf(),
    page: 1,
    billingId: null,
    size: 10,
    status: 'all',
  });

  const setShowNotice = status => {
    showNotice.value = status;
  }

  const setDetailData = (data, type, reload) => {
    const newData = {...data};
    if (data.billingId) {
      newData.type = type;
    }
    detailData.value = newData;
    if (reload) {
      getData();
    }
  }

  watch([() => params.value.startBillingDateStr,
    () => params.value.endBillingDateStr,
    () => params.value.status
  ],
    () => {
      getData();
  })

  const toAccountInfo = () => {
    const { href } = router.resolve({ path: `/seller/account-info` });
    window.open(href, '_self');
  }

  const setParams = data => {
    if (data) {
      params.value = data;
    } else {
      params.value = {
        marketplace: CURRENCY_MAPPER[countryCode].host,
        startBillingDateStr: moment().subtract(31, "day").valueOf(),
        endBillingDateStr: moment().valueOf(),
        page: 1,
        size: 10,
        status: null,
        billingId: null
      };
      getData();
    }
  }

  const getAccountData = async () => {
    const accountInfoRes = await connectionApi.getSellerAccountInfoGetAccountInfo();
    const addressInfoRes = await connectionApi.getSellerBillingAddressGetBillingAddressInfo();
    if (accountInfoRes.success && accountInfoRes.success) {
      hasCompleteInfo.value = accountInfoRes.data.operationContactNumber && addressInfoRes.data.phone;
    }
  };

  const getData = async () => {
    spinning.value = true;
    const paramsData = {...params.value};
    paramsData.startBillingDateStr = `${moment(paramsData.startBillingDateStr).format('YYYY-MM')}-01`;
    paramsData.endBillingDateStr = `${moment(paramsData.endBillingDateStr).format('YYYY-MM')}-01`;
    if (paramsData.status === 'all') {
      delete paramsData.status;
    }
    if (!paramsData.billingId) {
      delete paramsData.billingId;
    }
    const res = await financeApi.getFinanceSellerBillingsList(paramsData);
    const { data, success } = res;
    if (success) {
      listTotal.value = data.total;
      list.value = data.data;
    }
    spinning.value = false;
  };

  onMounted(() => {
    getData();
    getAccountData();
  })

</script>
<style lang="less" scoped>
  .payments {
    padding: 0 16px;
    .content {
      padding: 16px;
      overflow: hidden;
      border-radius: 12px;
      background-color: var(--color-white);
      box-shadow: var(--section-box-shadow-6);
    }
  }
  .notice-content {
    display: flex;
    flex-direction: column;
    button {
      align-self: flex-end;
      margin-top: 20px;
    }
  }
</style>
